import Layout from "components/layout";
import SEO from "components/seo";
import Seattle from "components/locationIndexPages/Seattle";
import React from "react";

export default function index() {
  const title = "Seattle Translation Services - Translation Agency";
  const description =
    "Need Seattle translation services? Tomedes provides translation, localization, and interpretation solutions in 120+ languages with rapid delivery and 24/7 support.";
  const keywords = "transaltion in seattle";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/locations/seattle-translation-services"
      />
      <Seattle />
    </Layout>
  );
}
